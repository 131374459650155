import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppStore } from "app/_shared/stores/app.store";
import { Permission } from "app/_shared/types/models/role";

@Component({
  selector: "app-side-bar",
  template: `
    <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li
        nz-submenu
        [nzOpen]="currentUrl.includes('operation') || currentUrl.includes('finance')"
        nzTitle="Dashboard"
        nzIcon="dashboard"
        [hidden]="
          !(
            permissions.includes(Permission.VIEW_OPERATION_DASHBOARD) ||
            permissions.includes(Permission.VIEW_FINANCE_DASHBOARD)
          )
        ">
        <ul>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_OPERATION_DASHBOARD)">
            <a routerLink="/operation">Operation</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_FINANCE_DASHBOARD)">
            <a routerLink="/finance">Finance</a>
          </li>
        </ul>
      </li>
      <li
        nz-menu-item
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Orders' : ''"
        [hidden]="!permissions.includes(Permission.VIEW_ORDERS)">
        <i nz-icon nzType="project"></i>
        <span><a routerLink="/orders">Orders</a></span>
      </li>
      <li
        nz-submenu
        [nzOpen]="currentUrl.includes('reports')"
        nzTitle="Reports"
        nzIcon="pie-chart"
        [hidden]="
          !(
            permissions.includes(Permission.VIEW_BUSINESS_SALES_REPORT) ||
            permissions.includes(Permission.VIEW_BUSINESS_SALES_RESULTS_REPORT) ||
            permissions.includes(Permission.VIEW_BUSINESS_PURCHASE_REPORT) ||
            permissions.includes(Permission.VIEW_BUSINESS_LOGISTIC_REPORT) ||
            permissions.includes(Permission.VIEW_FINANCIAL_GROSS_MARGIN_ORDER_REPORT) ||
            permissions.includes(Permission.VIEW_FINANCIAL_GROSS_MARGIN_PRODUCT_REPORT) ||
            permissions.includes(Permission.VIEW_FINANCIAL_PAYMENT_MONITORING_REPORT) ||
            permissions.includes(Permission.VIEW_LOGISTIC_OPEN_MILESTONE_REPORT) ||
            permissions.includes(Permission.VIEW_LOGISTIC_ORDER_KEY_MILESTONE_OVERVIEW_REPORT) ||
            permissions.includes(Permission.VIEW_LOGISTIC_PRODUCT_KEY_MILESTONE_OVERVIEW_REPORT)
          )
        ">
        <ul>
          <li
            nz-submenu
            [nzOpen]="currentUrl.includes('reports/business')"
            nzTitle="Business"
            [hidden]="
              !(
                permissions.includes(Permission.VIEW_BUSINESS_SALES_REPORT) ||
                permissions.includes(Permission.VIEW_BUSINESS_SALES_RESULTS_REPORT) ||
                permissions.includes(Permission.VIEW_BUSINESS_PURCHASE_REPORT) ||
                permissions.includes(Permission.VIEW_BUSINESS_LOGISTIC_REPORT) ||
                permissions.includes(Permission.VIEW_BUSINESS_INVENTORY_REPORT)
              )
            ">
            <ul>
              <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_BUSINESS_PURCHASE_REPORT)">
                <a routerLink="/reports/business/purchase">Purchase</a>
              </li>
              <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_BUSINESS_SALES_REPORT)">
                <a routerLink="/reports/business/sales">Sales</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                [hidden]="!permissions.includes(Permission.VIEW_BUSINESS_SALES_RESULTS_REPORT)">
                <a routerLink="/reports/business/sales-results">Sales result</a>
              </li>
              <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_BUSINESS_LOGISTIC_REPORT)">
                <a routerLink="/reports/business/logistic">Logistic</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                [hidden]="!permissions.includes(Permission.VIEW_BUSINESS_INVENTORY_REPORT)">
                <a routerLink="/reports/business/inventory">Inventory</a>
              </li>
            </ul>
          </li>
          <li
            nz-submenu
            [nzOpen]="currentUrl.includes('reports/financial')"
            nzTitle="Financial"
            [hidden]="
              !(
                permissions.includes(Permission.VIEW_FINANCIAL_GROSS_MARGIN_ORDER_REPORT) ||
                permissions.includes(Permission.VIEW_FINANCIAL_GROSS_MARGIN_PRODUCT_REPORT) ||
                permissions.includes(Permission.VIEW_FINANCIAL_PAYMENT_MONITORING_REPORT)
              )
            ">
            <ul>
              <li
                nz-menu-item
                nzMatchRouter
                [hidden]="!permissions.includes(Permission.VIEW_FINANCIAL_GROSS_MARGIN_ORDER_REPORT)">
                <a routerLink="/reports/financial/gross-margin-order">Gross margin order</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                [hidden]="!permissions.includes(Permission.VIEW_FINANCIAL_GROSS_MARGIN_PRODUCT_REPORT)">
                <a routerLink="/reports/financial/gross-margin-product">Gross margin product</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                [hidden]="!permissions.includes(Permission.VIEW_FINANCIAL_PAYMENT_MONITORING_REPORT)">
                <a routerLink="/reports/financial/payment-monitoring">Payment monitoring</a>
              </li>
            </ul>
          </li>
          <li
            nz-submenu
            [nzOpen]="currentUrl.includes('reports/logistic')"
            nzTitle="Logistic"
            [hidden]="
              !(
                permissions.includes(Permission.VIEW_LOGISTIC_OPEN_MILESTONE_REPORT) ||
                permissions.includes(Permission.VIEW_LOGISTIC_ORDER_KEY_MILESTONE_OVERVIEW_REPORT) ||
                permissions.includes(Permission.VIEW_LOGISTIC_PRODUCT_KEY_MILESTONE_OVERVIEW_REPORT)
              )
            ">
            <ul>
              <li
                nz-menu-item
                nzMatchRouter
                [hidden]="!permissions.includes(Permission.VIEW_LOGISTIC_OPEN_MILESTONE_REPORT)">
                <a routerLink="/reports/logistic/open-milestone">Open milestone</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                [hidden]="!permissions.includes(Permission.VIEW_LOGISTIC_ORDER_KEY_MILESTONE_OVERVIEW_REPORT)">
                <a routerLink="/reports/logistic/order-key-milestone-overview">Order key milestone</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                [hidden]="!permissions.includes(Permission.VIEW_LOGISTIC_PRODUCT_KEY_MILESTONE_OVERVIEW_REPORT)">
                <a routerLink="/reports/logistic/product-key-milestone-overview">Product key milestone</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        [nzOpen]="currentUrl.includes('companies') || currentUrl.includes('contacts')"
        nzTitle="Companies"
        nzIcon="bank"
        [hidden]="!(permissions.includes(Permission.VIEW_COMPANIES) || permissions.includes(Permission.VIEW_CONTACTS))">
        <ul>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_COMPANIES)">
            <a routerLink="/companies">Companies</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_CONTACTS)">
            <a routerLink="/contacts">Contacts</a>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        [nzOpen]="currentUrl.includes('products') || currentUrl.includes('families') || currentUrl.includes('cogs')"
        nzTitle="Products"
        nzIcon="shop"
        [hidden]="
          !(
            permissions.includes(Permission.VIEW_PRODUCTS) ||
            permissions.includes(Permission.VIEW_PRODUCT_FAMILIES) ||
            permissions.includes(Permission.VIEW_PRODUCT_COGS) ||
            permissions.includes(Permission.VIEW_PRODUCT_PRICES)
          )
        ">
        <ul>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_PRODUCTS)">
            <a routerLink="/products">Products</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_PRODUCT_FAMILIES)">
            <a routerLink="/families">Families</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_PRODUCT_COGS)">
            <a routerLink="/cogs">Cogs</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_PRODUCT_PRICES)">
            <a routerLink="/prices">Prices</a>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        [nzOpen]="
          currentUrl.includes('warehouses') ||
          currentUrl.includes('transactions') ||
          currentUrl.includes('reservations')
        "
        nzTitle="Warehouses"
        nzIcon="group"
        [hidden]="
          !(
            permissions.includes(Permission.VIEW_WAREHOUSES) ||
            permissions.includes(Permission.VIEW_INVENTORY_ITEMS) ||
            permissions.includes(Permission.VIEW_PRODUCT_COGS) ||
            permissions.includes(Permission.VIEW_PRODUCT_PRICES)
          )
        ">
        <ul>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_WAREHOUSES)">
            <a routerLink="/warehouses">Warehouses</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_WAREHOUSES)">
            <a routerLink="/transactions">Transactions</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_INVENTORY_ITEMS)">
            <a routerLink="/reservations">Reservations</a>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        [nzOpen]="currentUrl.includes('batches') || currentUrl.includes('own-products')"
        nzTitle="Batches"
        nzIcon="barcode"
        [hidden]="!permissions.includes(Permission.VIEW_BATCHES)">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/batches">Batches</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/own-products">Own products</a>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        [nzOpen]="currentUrl.includes('users') || currentUrl.includes('roles')"
        nzTitle="Users"
        nzIcon="usergroup-delete"
        [hidden]="!(permissions.includes(Permission.VIEW_USERS) || permissions.includes(Permission.VIEW_ROLES))">
        <ul>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_USERS)">
            <a routerLink="/users">Users</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_ROLES)">
            <a routerLink="/roles">Roles</a>
          </li>
        </ul>
      </li>
      <li
        nz-menu-item
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Documents' : ''"
        [hidden]="!permissions.includes(Permission.VIEW_DOCUMENTS)">
        <i nz-icon nzType="file"></i>
        <span><a routerLink="/documents">Documents</a></span>
      </li>
      <li
        nz-submenu
        [nzOpen]="
          currentUrl.includes('milestone-templates') ||
          currentUrl.includes('in-transit-time-templates') ||
          currentUrl.includes('payment-templates')
        "
        nzTitle="Templates"
        nzIcon="file-done"
        [hidden]="
          !(
            permissions.includes(Permission.VIEW_PAYMENT_TEMPLATES) ||
            permissions.includes(Permission.VIEW_IN_TRANSIT_TIME_TEMPLATES) ||
            permissions.includes(Permission.VIEW_MILESTONE_TEMPLATES)
          )
        ">
        <ul>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_MILESTONE_TEMPLATES)">
            <a routerLink="/milestone-templates">Milestone templates</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_IN_TRANSIT_TIME_TEMPLATES)">
            <a routerLink="/in-transit-time-templates">In transit time templates</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_PAYMENT_TEMPLATES)">
            <a routerLink="/payment-templates">Payment templates</a>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        [nzOpen]="
          currentUrl.includes('addresses') ||
          currentUrl.includes('ports') ||
          currentUrl.includes('company-types') ||
          currentUrl.includes('document-categories') ||
          currentUrl.includes('currencies') ||
          currentUrl.includes('regions') ||
          currentUrl.includes('email-ccs') ||
          currentUrl.includes('markets') ||
          currentUrl.includes('file-templates')
        "
        nzTitle="Settings"
        nzIcon="setting"
        [hidden]="
          !(
            permissions.includes(Permission.VIEW_ADDRESSES) ||
            permissions.includes(Permission.VIEW_COMPANY_TYPES) ||
            permissions.includes(Permission.VIEW_DOCUMENT_CATEGORIES) ||
            permissions.includes(Permission.VIEW_CURRENCIES) ||
            permissions.includes(Permission.VIEW_REGIONS) ||
            permissions.includes(Permission.VIEW_EMAIL_CC) ||
            permissions.includes(Permission.VIEW_REPORTING_MARKETS) ||
            permissions.includes(Permission.VIEW_FILE_TEMPLATES)
          )
        ">
        <ul>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_ADDRESSES)">
            <a routerLink="/addresses">Addresses</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_ADDRESSES)">
            <a routerLink="/ports">Ports</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_COMPANY_TYPES)">
            <a routerLink="/company-types">Company types</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_REPORTING_MARKETS)">
            <a routerLink="/markets">Markets</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_REGIONS)">
            <a routerLink="/regions">Regions</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_DOCUMENT_CATEGORIES)">
            <a routerLink="/document-categories">Documents categories</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_CURRENCIES)">
            <a routerLink="/currencies">Currencies</a>
          </li>
          <li nz-menu-item nzMatchRouter [hidden]="!permissions.includes(Permission.VIEW_EMAIL_CC)">
            <a routerLink="/email-ccs">Email cc</a>
          </li>
          <li
            nz-submenu
            [nzOpen]="currentUrl.includes('file-templates')"
            nzTitle="File templates"
            [hidden]="!permissions.includes(Permission.VIEW_FILE_TEMPLATES)">
            <ul>
              <li nz-menu-item nzMatchRouter>
                <a routerLink="/file-templates/proforma-invoice">Proforma invoice</a>
              </li>
              <li nz-menu-item nzMatchRouter>
                <a routerLink="/file-templates/commercial-invoice">Commercial invoice</a>
              </li>
              <li nz-menu-item nzMatchRouter>
                <a routerLink="/file-templates/purchase-order">Purchase order</a>
              </li>
              <li nz-menu-item nzMatchRouter>
                <a routerLink="/file-templates/invoice-from-agent">Invoice from agent</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  `,
})
export class SideBarComponent {
  isCollapsed: boolean;
  permissions: Permission[] = [];
  Permission = Permission;

  currentUrl: string;

  constructor(
    private router: Router,
    private appStore: AppStore,
  ) {
    this.currentUrl = this.router.url;
    this.appStore.isCollapsed$.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
    this.appStore.permissions$.subscribe((permissions) => {
      this.permissions = permissions;
    });
  }
}
