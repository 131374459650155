import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import {
  addEntities,
  deleteEntities,
  getAllEntities,
  getEntity,
  selectAllEntities,
  selectEntity,
  selectManyByPredicate,
  setEntities,
  upsertEntities,
  withEntities,
} from "@ngneat/elf-entities";
import { ICompany } from "app/_shared/types/models/company";
import { take } from "rxjs";

const store = createStore({ name: "companies" }, withEntities<ICompany, "_id">({ idKey: "_id" }));

@Injectable({ providedIn: "root" })
export class CompanyStore {
  findAll$ = store.pipe(selectAllEntities(), take(1));
  getAll$ = store.pipe(selectManyByPredicate((entity) => entity.isActive));
  getOne$ = (id: string) => store.pipe(selectEntity(id));

  warehouses$ = store.pipe(
    selectManyByPredicate((entity) => {
      if (!entity.isActive) return false;
      const types = entity.types.map(({ displayName }) => displayName);
      return types.includes("WAREHOUSE");
    }),
  );
  clients$ = store.pipe(
    selectManyByPredicate((entity) => {
      if (!entity.isActive) return false;
      const types = entity.types.map(({ displayName }) => displayName);
      return (
        types.includes("DIRECT CUSTOMER") ||
        types.includes("LOCAL CUSTOMER") ||
        types.includes("DISTRIBUTOR") ||
        types.includes("SUB-DISTRIBUTOR")
      );
    }),
  );
  tollManufacturers$ = store.pipe(
    selectManyByPredicate((entity) => {
      if (!entity.isActive) return false;
      const types = entity.types.map(({ displayName }) => displayName);
      return types.includes("TOLL MANUFACTURER");
    }),
  );

  data = () => store.query(getAllEntities()).filter((entity) => entity.isActive);
  getById = (id: string) => store.query(getEntity(id));

  setEntities(state: ICompany[]) {
    store.update(setEntities(state));
  }

  addEntities(state: ICompany[]) {
    store.update(addEntities(state, { prepend: true }));
  }

  updateEntities(state: ICompany[]) {
    store.update(upsertEntities(state));
  }

  removeEntities(ids: string[]) {
    store.update(deleteEntities(ids));
  }

  destroy() {
    store.reset();
  }
}
